import React from "react"
import { Link } from "gatsby"

import "./index.scss"

const Footer = () => (
  <footer>
    <div className="container">
      <div className="row">
        <div className="col-12">
          <span>Solomon Motor Group</span>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <nav className="nav justify-content-center">
            <Link to="/terms-of-use" className="nav-link">
              Terms of Use
            </Link>
            <span>|</span>
            <Link to="/privacy-policy" className="nav-link">
              Privacy Policy
            </Link>
          </nav>
        </div>
      </div>
      <div className="row">
        <div className="col-12 tag">
          <span>Site by </span>
          <a
            href="https://www.dssdigital.net/?utm_source=website&utm_medium=tag&utm_campaign=solomon-motor-group"
            target="_blank"
            rel="noopener noreferrer"
          >
            DSS Digital
          </a>
        </div>
      </div>
    </div>
  </footer>
)

export default Footer
