import React from "react"
import { Link } from "gatsby"
import Carousel from "react-bootstrap/Carousel"
import Button from "react-bootstrap/Button"
import Icon from "../../Icon"

import "./index.scss"

const fileBucket = process.env.FILE_BUCKET

const Header = () => {
  return (
    <>
      <Carousel controls={false} fade={true} indicators={false}>
        <Carousel.Item>
          <img
            className="header-background"
            src={`${fileBucket}/headers/header1_2780.jpg`}
            alt=""
            srcSet={`
              ${fileBucket}/headers/header1_1152.jpg 1152w,
              ${fileBucket}/headers/header1_1536.jpg 1536w,
              ${fileBucket}/headers/header1_1984.jpg 1984w,
              ${fileBucket}/headers/header1_2400.jpg 2400w,
              ${fileBucket}/headers/header1_2780.jpg 2780w,
            `}
            role="presentation"
          />
          <Carousel.Caption>
            <p className="dealership">Solomon Motors City</p>
            <p className="location">Cnr Princes Highway &amp; Quinns Lane</p>
            <Link to="/used-vehicles/solomon-motors-city">
              <Button variant="primary" bsPrefix="smg">
                View Stock <Icon prefix="fas" name="arrow-circle-right" />
              </Button>
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="header-background"
            src={`${fileBucket}/headers/header2_2780.jpg`}
            alt=""
            srcSet={`
              ${fileBucket}/headers/header2_1152.jpg 1152w,
              ${fileBucket}/headers/header2_1536.jpg 1536w,
              ${fileBucket}/headers/header2_1984.jpg 1984w,
              ${fileBucket}/headers/header2_2400.jpg 2400w,
              ${fileBucket}/headers/header2_2780.jpg 2780w,
            `}
            role="presentation"
          />
          <Carousel.Caption>
            <p className="dealership">Solomons Mid City Autos</p>
            <p className="location">Cnr East &amp; North Streets</p>
            <Link to="/used-vehicles/solomons-mid-city-autos">
              <Button variant="primary" bsPrefix="smg">
                View Stock <Icon prefix="fas" name="arrow-circle-right" />
              </Button>
            </Link>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </>
  )
}

export default Header
