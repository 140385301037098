import React from "react"
import { Link } from "gatsby"
import Navbar from "react-bootstrap/Navbar"
import brand from "./smg-brand.jpg"

import "./index.scss"

const Navi = (props) => {
  const activeItem = (path) => {
    if (props.location.pathname === path) {
      return "nav-item active"
    } else {
      return "nav-item"
    }
  }

  return (
    <Navbar expand="lg" bg="light-trans">
      <div className="container main-nav-container">
        <Navbar.Brand href="/">
          <img alt="" src={brand} className="d-inline-block align-middle" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="main-nav">
          <ul className="navbar-nav nav-fill">
            <li className={activeItem("/used-vehicles")}>
              <Link to="/used-vehicles" className="nav-link">
                Used Vehicles
              </Link>
            </li>
            <li className={activeItem("/our-staff")}>
              <Link to="/our-staff" className="nav-link">
                Our Staff
              </Link>
            </li>
            <li className={activeItem("/our-history")}>
              <Link to="/our-history" className="nav-link">
                Our History
              </Link>
            </li>
            <li className={activeItem("/contact")}>
              <Link to="/contact" className="nav-link">
                Contact
              </Link>
            </li>
          </ul>
        </Navbar.Collapse>
      </div>
    </Navbar>
  )
}

export default Navi
